import React from 'react';

export default function Widget() {
	return (
		<>
			<div className="modal fade" id="modal-default">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h4 className="modal-title"> </h4>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">×</span>
							</button>
						</div>
						<div className="modal-body" />
						<div className="modal-footer justify-content-between" style={{ display: 'none' }}>
							<button type="button" className="btn btn-default" data-dismiss="modal">Fechar</button>
							<button type="button" id="modal-action" />
						</div>
					</div>
				</div>
			</div>
			<div id="tut-init">
				<div id="tut-init-inner">
					<i className="fa fa-spinner fa-pulse fa-3x fa-fw" />
				</div>
			</div>
			<div id="tut-loader" style={{ display: 'none' }}>
				<div id="tut-loader-inner">
					<i className="fa fa-spinner fa-pulse fa-3x fa-fw" />
				</div>
			</div>
			<div id="tut-alert" style={{ display: 'none' }} />
		</>
	);
}
