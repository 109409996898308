import React from 'react';

export default function HomeContent() {
	return (
		<div className="content-wrapper">
			<div className="content-header">
				<div className="container-fluid">
					<div className="row mb-2">
						<div id="tut-title" className="col-sm-6">
							<h1>TUT SYSTEM</h1>
						</div>
					</div>
				</div>
			</div>
			<div className="content">
				<div className="container-fluid">
					<div className="row">
						<div className="col-12">
							<div className="card">
								<div id="tut-subtitle" className="card-header">Seja bem-vindo ao TUT.</div>
								<div id="tut-content" className="card-body">
									<div className="banner">
										<img src="/dist/img/react.svg" alt="tut" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<input type="hidden" id="page-type" value="home"></input>
		</div>
	);
}
