import React from 'react';

export default function Auth() {
	return (
		<div id="tut-auth">
			<div className="card card-info" style={{ display: 'none' }}>
				<div className="card-header">
					<h3 className="card-title">Autenticação</h3>
					<a href="#" id="tut-auth-reset" />
				</div>
				<div id="tut-auth-tab-1" className="card-body">
					<div className="form-group row">
						<label htmlFor="input-phone" className="col-sm-2 col-form-label">Telefone</label>
						<div className="col-sm-10">
							<input type="text" className="form-control" id="input-phone" aria-haspopup="false" autoCapitalize="off" autoComplete="off" autoCorrect="off" spellCheck="false" />
						</div>
					</div>
					<div className="form-group row">
						<label htmlFor="input-pass" className="col-sm-2 col-form-label">Senha</label>
						<div className="col-sm-10">
							<input type="password" className="form-control" id="input-pass" maxLength={100} aria-haspopup="false" autoCapitalize="off" autoComplete="off" autoCorrect="off" spellCheck="false" />
							<a href="#" id="alter-pass">
								<i className="fa fa-eye tut-readable" />
							</a>
						</div>
					</div>
				</div>
				<div id="tut-auth-tab-2" className="card-body" style={{ display: 'none' }}>
					<div className="form-group row">
						<label htmlFor="input-code" className="col-sm-2 col-form-label">Código</label>
						<div className="col-sm-10">
							<input type="text" className="form-control" id="input-code" aria-haspopup="false" autoCapitalize="off" autoComplete="off" autoCorrect="off" spellCheck="false" />
						</div>
					</div>
				</div>
				<div className="card-footer">
					<button className="btn btn-info" id="btn-log-in">Entrar</button>
					<button className="btn btn-info" id="btn-verify-code" style={{ display: 'none' }}>Verificar</button>
					<button type="submit" className="btn btn-default float-right" id="btn-resend-code" style={{ display: 'none' }}>Reenviar código</button>
				</div>
			</div>
		</div>
	);
}
