import React from 'react';

export default function Modal() {
	return (
		<>
			<div id="tut-modal" className="modal" tabIndex={-1} role="dialog">
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 id="tut-modal-title" className="modal-title" />
							<button type="button" id="tut-modal-close" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">×</span>
							</button>
						</div>
						<div id="tut-modal-body" className="modal-body" />
						<div className="modal-footer">
							<button type="button" id="tut-modal-cancel" className="btn btn-secondary" data-dismiss="modal" />
							<button type="button" id="tut-modal-confirm" className="btn btn-primary" />
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
