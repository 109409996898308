import React from 'react';

import Auth from '../components/Auth';
import Footer from '../components/Footer';
import Menu from '../components/Menu';
import Modal from '../components/Modal';
import PaymentsContent from '../contents/PaymentsContent';
import Sidebar from '../components/Sidebar';
import Widget from '../components/Widget';

export default function Payments() {
	return (
		<>
			<div className="wrapper">
				{ <Sidebar/> }
				{ <Menu/> }
				{ <PaymentsContent/> }
				{ <Footer/> }
				{ <Modal/> }
			</div>
			{ <Auth/> }
			{ <Widget/> }
		</>
	);
}
