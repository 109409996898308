import React from 'react';

export default function Sidebar() {
	return (
		<>
			<aside className="main-sidebar sidebar-dark-primary elevation-4">
				<a href="/" className="brand-link">
					<img src="/dist/img/react.svg" alt="Tut" className="brand-image img-circle elevation-3" style={{ opacity: '.8' }} />
					<span className="brand-text font-weight-light">TUT SYSTEM</span>
				</a>
				<div className="sidebar">
					<div className="user-panel mt-3 pb-3 mb-3 d-flex">
						<div className="info">
							<span className="d-block tut-text-white" />
						</div>
					</div>
					<nav className="mt-2">
						<ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
							<li className="nav-header">PAGAMENTOS NÃO INCLUIDOS</li>
							<li className="nav-item">
								<span id="nav-link-daily-payments" className="nav-link tut-clickable">
									<i className="fa fa-circle nav-icon"></i>
									<p className="tut-text-white">
										Solicitados
									</p>
								</span>
							</li>
							<li className="nav-item">
								<span id="nav-link-dispute-payments" className="nav-link tut-clickable">
									<i className="fa fa-circle nav-icon"></i>
									<p className="tut-text-white">
										Disputas
									</p>
								</span>
							</li>
							<li className="nav-item">
								<span id="nav-link-dpay-payments" className="nav-link tut-clickable">
									<i className="fa fa-circle nav-icon"></i>
									<p className="tut-text-white">
										Saques do Dpay
									</p>
								</span>
							</li>
							<li className="nav-item">
								<span id="nav-link-scheduled-payments" className="nav-link tut-clickable">
									<i className="fa fa-circle nav-icon"></i>
									<p className="tut-text-white">
										Pagamentos agendados
									</p>
								</span>
							</li>

							<li className="nav-header">PAGAMENTOS INCLUÍDOS</li>
							<li className="nav-item">
								<span id="nav-link-daily-historic" className="nav-link tut-clickable">
									<i className="fa fa-circle nav-icon"></i>
									<p className="tut-text-white">
										Solicitados
									</p>
								</span>
							</li>
							<li className="nav-item">
								<span id="nav-link-dispute-historic" className="nav-link tut-clickable">
									<i className="fa fa-circle nav-icon"></i>
									<p className="tut-text-white">
										Disputas
									</p>
								</span>
							</li>
							<li className="nav-item">
								<span id="nav-link-dpay-historic" className="nav-link tut-clickable">
									<i className="fa fa-circle nav-icon"></i>
									<p className="tut-text-white">
										Saques do Dpay
									</p>
								</span>
							</li>
							<li className="nav-item">
								<span id="nav-link-scheduled-historic" className="nav-link tut-clickable">
									<i className="fa fa-circle nav-icon"></i>
									<p className="tut-text-white">
										Pagamentos agendados
									</p>
								</span>
							</li>
						</ul>
					</nav>
				</div>
			</aside>
		</>
	);
}
