import React from 'react';

export default function PaymentsContent() {
	let a = 0;

	const months = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];
	const monthsOpt = [];

	const now = new Date();

	for (const item of months) {
		const month = String(a + 1);
		const value = (month.length === 2 ? month : "0" + month);
		monthsOpt.push(<option key={value} value={value}>{item}</option>);
		a++;
	}

	const year = now.getFullYear();
	const yearsOpt = [];

	for (let x = year; x > year - 10; x--)
		yearsOpt.push(<option key={x} value={x}>{x}</option>);

	const daysOpt = [];

	for (let x = 1; x <= 31; x++)
		daysOpt.push(<option key={x} value={x}>{x}</option>);

	return (		
		<div className="content-wrapper">
			<div className="content-header">
				<div className="container-fluid">
					<div className="row mb-2">
						<div className="col-sm-6">
							<h1 id="tut-title">Pagamentos</h1>
							<h2 id="tut-title2"></h2>
						</div>
					</div>
				</div>
			</div>
			<div className="content">
				<div className="container-fluid">
					<div className="row">
						<div id="tut-datepicker" className="col-12">
							<div className="card card-primary">
								<div className="card-header">
									<h3 id="tut-subtitle" className="card-title"></h3>
									<a href="#" id="filter-btn" className="float-right">Filtrar</a>
								</div>
								<div className="card-body tut-datepicker-body">
									<div className="row">
										<div className="col-sm-6 main-filter historic-filter d-none">
											<div className="form-group tut-datepicker-item">
												<div className="input-group date" id="sd" data-target-input="nearest">
													<input type="text" id="start" className="form-control form-control-border datetimepicker-input" data-target="#sd" placeholder="00/00/0000" defaultValue />
													<div className="input-group-append" data-target="#sd" data-toggle="datetimepicker">
														<div className="input-group-text"><i className="fa fa-calendar" /></div>
													</div>
												</div>
											</div>
										</div>
										<div className="col-sm-6 main-filter historic-filter d-none">
											<div className="form-group tut-datepicker-item">
												<div className="input-group date" id="fd" data-target-input="nearest">
													<input type="text" id="final" className="form-control form-control-border datetimepicker-input" data-target="#fd" placeholder="00/00/0000" defaultValue />
													<div className="input-group-append" data-target="#fd" data-toggle="datetimepicker">
														<div className="input-group-text"><i className="fa fa-calendar" /></div>
													</div>
												</div>
											</div>
										</div>
										<div className="col-sm-3 main-filter list-filter d-none">
											<div className="form-group tut-datepicker-item">
												<select id="month" className="custom-select form-control-border">
													{monthsOpt}
												</select>
											</div>
										</div>
										<div className="col-sm-3 main-filter list-filter d-none">
											<div className="form-group tut-datepicker-item">
												<select id="year" className="custom-select form-control-border">
													{yearsOpt}
												</select>
											</div>
										</div>
										<div className="col-sm-3 main-filter list-filter d-none">
											<div className="form-group tut-datepicker-item">
												<select id="start-day" className="custom-select form-control-border">
													<option value="0">Todos os dias</option>
													{daysOpt}
												</select>
											</div>
										</div>
										<div className="col-sm-3 main-filter list-filter d-none">
											<div className="form-group tut-datepicker-item">
												<select id="final-day" className="custom-select form-control-border">
													<option value="0">Todos os dias</option>
													{daysOpt}
												</select>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div id="payment-data" className="col-12" style={{ display: 'none' }}>
							<div className="card">
								<div className="card-header table-header">
									<h3 id="tut-subtitle2" className="card-title">Nenhum pagamento selecionado.</h3>
									<button className="btn btn-warning tut-btn-pay">INCLUIR SELECIONADOS</button>
								</div>
								<div id="tut-content" className="card-body" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
